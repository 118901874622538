<template>
  <main class="container">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <!--CONFIRM DELETE DIALOG-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_delete ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmDelete()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Parent Menu
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmDelete()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="deleteItem()"
                >
                  {{ process ? "Processing..." : "Delete" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--CONFIRM DELETE VIEW DIALOG-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_view_delete ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmViewDelete()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Views
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmViewDelete()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="deleteViewItem()"
                >
                  {{ process ? "Processing..." : "Delete" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CreateView
      v-if="showCreateViewModal"
      v-bind:show="showCreateViewModal"
      @close="closeCreateViewModal"
      @alertSuccess="alertSuccess"
      @reload="getView"
      @alertError="alertError"
      :singleMenu="singleMenu"
      :modelProperty="modelProperty"
      :linkingOperator="linkingOperator"
      :sortingValue="sortingValue"
      :menuViewMode="menuViewMode"
      :allRoles="allRole"
    />
    <CreateParentMenu
      v-bind:show="showCreateModal"
      :allRoles="allRole"
      @close="closeCreateModal"
      @alertSuccess="alertSuccess"
      @reload="getAllMenu"
      @alertError="alertError"
    />

    <DeleteModal
      :show="!userCanViewViews"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <EditParentMenu
      v-bind:show="showEditModal"
      v-bind:close="closeEditModal"
      @close="closeEditModal"
      @alertSuccess="alertSuccess"
      @alertError="alertError"
      @reload="getAllMenu"
      v-bind:singleMenu="singleMenu"
      :allRoles="allRole"
    />
    <EditView
      v-bind:show="showEditViewModal"
      v-bind:close="closeEditViewModal"
      @close="closeEditViewModal"
      @alertSuccess="alertSuccess"
      @alertError="alertError"
      @reload="getView"
      :singleMenu="singleMenu"
      :singleView="singleView"
      :modelProperty="modelProperty"
      :linkingOperator="linkingOperator"
      :sortingValue="sortingValue"
      :menuViewMode="menuViewMode"
      v-if="showEditViewModal"
      :allRoles="allRole"
    />

    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-end justify-content-between">
          <div class="col-5">
            <label class="form__label">Parent Menu</label>
            <select
              class="select select--lg"
              @change="onChange($event)"
              v-model="customMenuKey"
            >
              <option value="null" disabled selected v-if="!menuLoading">
                Select your Parent Menu
              </option>
              <option value="null" disabled selected v-else>Loading...</option>
              <template v-if="!menuLoading">
                <option
                  v-for="item in allMenu"
                  :key="item.customMenuKey"
                  v-bind:value="item.customMenuKey"
                >
                  {{ item.menuName }}
                </option>
              </template>
            </select>
          </div>
          <div class="col-7">
            <div class="row align-items-center justify-content-between">
              <div class="col-4">
                <button
                  type="button"
                  data-modal="#addModal"
                  class="w-100 button page__body__header__button button--grey text--capital"
                  v-if="userCanCreateViews"
                  @click="openCreateModal()"
                >
                  <span class="d-inline-flex mr-2">add new</span>
                  <ion-icon name="add-outline" class="text--medium"></ion-icon>
                </button>
              </div>
              <div class="col-4">
                <button
                  type="button"
                  class="w-100 button page__body__header__button button--grey text--capital"
                  v-if="userCanEditViews"
                  @click="openEditModal(2)"
                >
                  <span class="d-inline-flex mr-2">edit</span>
                  <ion-icon
                    name="pencil-outline"
                    class="text--regular"
                  ></ion-icon>
                </button>
              </div>
              <div class="col-4">
                <button
                  data-modal="#deleteModal"
                  type="button"
                  class="button w-100 page__body__header__button button--grey text--capital"
                  @click="confirmDelete({ customMenuKey: customMenuKey })"
                  v-if="userCanDeleteViews"
                >
                  <span class="d-inline-flex mr-2">delete</span>
                  <ion-icon
                    name="trash-bin-outline"
                    class="text--regular"
                  ></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button
              type="button"
              data-toggle="modal"
              data-target="#addFieldModal"
              class="button page__body__header__button button--grey text--capital float-left mt-4"
              @click="openCreateViewModal()"
              v-if="singleMenu && userCanCreateViews"
            >
              <!-- v-if="!singleMenu.menuName.includes('Users')" -->
              <span class="d-inline-flex mr-2">Create New View </span>
              <ion-icon
                name="add-outline"
                class="text--medium md hydrated"
                role="img"
                aria-label="add outline"
              ></ion-icon>
            </button>
          </div>
          <div class="col-6"></div>
        </div>
      </div>

      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 45%"
            >
              name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 25%"
            >
              filter
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              owner
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            ></th>
          </tr>
        </thead>
        <tbody v-if="allView">
          <tr v-for="(row, ri) in allView.items" v-bind:key="ri">
            <td>
              <span class="table__body__text table__text text--capital">{{
                row.name
              }}</span>
            </td>
            <td style="padding-right: 15px !important">
              <span class="table__body__text table__text text--capital">{{
                row.filters
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                row.owner
              }}</span>
            </td>

            <!-- <td v-if="singleMenu && !singleMenu.menuName.includes('Users')"> -->
            <td>
              <b-dropdown text="Action" class="m-2 dropdown--button-grey">
                <b-dropdown-item
                  href="#"
                  @click="openEditViewModal(row)"
                  v-if="userCanEditViews"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="confirmViewDelete({ viewKey: row.customViewKey })"
                  v-if="userCanDeleteViews"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </td>
          </tr>
        </tbody>
        <tbody v-if="loadingAllView">
          <tr>
            <td colspan="5">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="allView.items && !loadingAllView">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary">{{
              allView ? allView.pageIndex : 0
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              allView ? allView.totalPages : 0
            }}</span>
            of
            <span>{{ allView ? allView.totalCount : 0 }}</span>
          </div>
          <div
            class="pagination__buttons d-flex align-items-center justify-content-between"
            v-if="allView"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              :disabled="!allView.hasPreviousPage"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              v-for="page in allView.totalPages"
              :key="page"
              :class="{
                'text--white button--primary ': allView.pageIndex === page,
              }"
              class="button text--regular button--sm ml-2"
              @click="changePage(page)"
            >
              {{ page }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              :disabled="!allView.hasNextPage"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import CreateView from "./create";
import CreateParentMenu from "./create_parent_menu";
import EditView from "./edit";
import EditParentMenu from "./edit_parent_menu";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import {
  GET_ALL_MENU,
  GET_SINGLE_MENU,
  GET_ALL_VIEW,
  GET_MENU_TYPES,
  GET_MODEL_PROPERTY,
  GET_LINKING_OPERATOR,
  GET_MENU_VIEW_MODE,
  GET_SINGLE_VIEW,
  GET_SORTING_VALUES,
  GET_GROUPED_MENU,
  GET_ROLES,
} from "@/core/services/store/types";
import ApiService from "../../../core/services/general.service";
export default {
  name: "ViewsIndex",
  components: {
    CreateView: CreateView,
    CreateParentMenu: CreateParentMenu,
    EditParentMenu: EditParentMenu,
    EditView: EditView,
    DeleteModal,
  },
  data: function () {
    return {
      page: 1,
      size: 10,
      status: true,
      category: null,
      confirm_status: false,
      confirm_delete: false,
      confirm_view_delete: false,
      view: null,
      viewKey: "",
      customMenuKey: null,
      customViewKey: null,
      viewStatus: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Custom Menu has been created",
      },
      process: false,
      showCreateModal: false,
      showCreateViewModal: false,
      showEditModal: false,
      showEditViewModal: false,
      filter_by: "deactivated",
      filter_by_label: "Filter <ion-icon name='caret-down-outline'/>",
      content_header: {
        title: "Views",
        subTitle:
          "Manage the products offerings that you have available to your customers by creating and editing loan and deposit products.",
      },
      itemMenuName: "",
    };
  },
  methods: {
    getView(page = null) {
      this.$store.dispatch(GET_ALL_VIEW, {
        key: this.customMenuKey,
        page: page ? page : this.page,
        size: this.size,
      });
      this.$store.dispatch(GET_GROUPED_MENU);
    },
    getMenuTypes() {
      this.$store.dispatch(GET_MENU_TYPES, {
        category: this.category,
      });
    },
    getAllMenu() {
      this.$store.dispatch(GET_ALL_MENU);
      this.$store.dispatch(GET_GROUPED_MENU);
    },
    getAllRoles() {
      this.$store.dispatch(GET_ROLES);
    },
    getLinkingOperator() {
      this.$store.dispatch(GET_LINKING_OPERATOR);
    },
    getMenuViewMode() {
      this.$store.dispatch(GET_MENU_VIEW_MODE);
    },
    getSortingValues() {
      this.$store.dispatch(GET_SORTING_VALUES);
    },
    changePage(page = null) {
      if (page === "next") {
        const nextPage = this.allView.hasNextPage
          ? this.allView.pageIndex + 1
          : 1;
        this.$store.dispatch(GET_ALL_VIEW, {
          key: this.customMenuKey,
          page: nextPage,
          size: this.size,
        });
      } else if (page === "previous") {
        const nextPage = this.allView.hasPreviousPage
          ? this.allView.pageIndex - 1
          : 1;
        this.$store.dispatch(GET_ALL_VIEW, {
          key: this.customMenuKey,
          page: nextPage,
          size: this.size,
        });
      } else if (page !== null) {
        this.$store.dispatch(GET_ALL_VIEW, {
          key: this.customMenuKey,
          page: page,
          size: this.size,
        });
      }
    },
    confirmDelete(row) {
      if (row) this.customMenuKey = row.customMenuKey;

      this.alert.show = false;
      if (!this.confirm_delete) {
        this.confirm_delete = true;
      } else {
        this.confirm_delete = false;
      }
    },
    confirmViewDelete(row) {
      if (row) this.viewKey = row.viewKey;

      this.alert.show = false;
      if (!this.confirm_view_delete) {
        this.confirm_view_delete = true;
      } else {
        this.confirm_view_delete = false;
      }
    },
    onChange(e) {
      let key = e.target.value;
      this.itemMenuName = key;
      let page = null;
      this.$store.dispatch(GET_SINGLE_MENU, key);
      this.$store.dispatch(GET_MODEL_PROPERTY, key);
      this.$store.dispatch(GET_ALL_VIEW, {
        key: this.customMenuKey,
        page: page ? page : this.page,
        size: this.size,
      });
    },
    deleteItem() {
      this.process = true;
      ApiService.delete(`CustomMenu/${this.customMenuKey}`)
        .then((res) => {
          this.process = false;
          this.confirm_delete = false;
          if (res.data.status) {
            this.getAllMenu();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_delete = false;
          try {
            this.process = false;
            this.alertError(e.response.data.message);
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    deleteViewItem() {
      this.process = true;
      ApiService.delete(`CustomView/${this.viewKey}`)

        .then((res) => {
          this.process = false;
          this.confirm_view_delete = false;
          if (res.data.status) {
            this.getView();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_view_delete = false;
          try {
            this.process = false;
            this.alertError(e.response.data.message);
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    openCreateViewModal() {
      this.showCreateViewModal = true;
    },
    openEditModal(row = null) {
      this.view = row;
      this.showEditModal = true;
    },
    openEditViewModal(row = null) {
      this.view = row;
      this.$store.dispatch(GET_SINGLE_VIEW, row.customViewKey);
      this.showEditViewModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    closeCreateViewModal() {
      this.showCreateViewModal = false;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    closeEditViewModal() {
      this.showEditViewModal = false;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:MM A");
      }
    },
  },
  watch: {},
  computed: {
    ...mapState({
      allMenu: (state) => state.views?.allMenu?.data || [],
      singleMenu: (state) => state.views?.singleMenu?.data || {},
      singleView: (state) => state.views?.singleView?.data || {},
      allView: (state) => state.views?.allView?.data || [],
      modelProperty: (state) => state.views?.modelProperty?.data || [],
      linkingOperator: (state) => state.views?.linkingOperator?.data || [],
      sortingValue: (state) => state.views?.sortingValue?.data || [],
      menuViewMode: (state) => state.views?.menuViewMode?.data || [],
      menuLoading: (state) => state.views.loadingAll,
      loading: (state) => state.views.loading,
      loadingAllView: (state) => state.views.loadingAllView,
      user: (state) => state.auth.user,
      loadingRoles: (state) => state.access.loadingRole,
      allRoles: (state) => state.access?.allRoles,
      allRole: (state) => state.access?.allRole?.data || [],
    }),
    userCanCreateViews() {
      const views = this.user.scopes.find(
        (item) => item.scopeName === Role.Views
      );
      const { permissionsList } = views;
      return permissionsList.CanCreateViews;
    },
    userCanEditViews() {
      const views = this.user.scopes.find(
        (item) => item.scopeName === Role.Views
      );
      const { permissionsList } = views;
      return permissionsList.CanEditViews;
    },
    userCanDeleteViews() {
      const views = this.user.scopes.find(
        (item) => item.scopeName === Role.Views
      );
      const { permissionsList } = views;
      return permissionsList.CanDeleteViews;
    },
    userCanViewViews() {
      const views = this.user.scopes.find(
        (item) => item.scopeName === Role.Views
      );
      const { permissionsList } = views;
      return permissionsList.CanViewViews;
    },
  },
  mounted() {
    this.getAllMenu();
    this.getLinkingOperator();
    this.getMenuViewMode();
    this.getSortingValues();
    this.getAllRoles();
  },
};
</script>
